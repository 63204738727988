body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*
========================================
Global
========================================
*/
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: verdana, arial, sans-serif, helvetica;
  background-color: #e5e5e5;
  font-size: 8pt;
  margin: 0;
}

a {
  font-weight: bold;
  text-decoration: none;
}

a:link {
  color: #003399;
}

a:visited {
  color: #003399;
}

a:hover {
  text-decoration: underline;
  color: #cc0000;
}

h2 {
  font-size: 9pt;
  margin: 0;
  font-weight: bold;
  min-height: 1rem;
}

p {
  line-height: 1.3;
}

/*
========================================
Master Container
========================================
*/
.master-container {
  margin: auto;
  width: 800px;
  background-color: white;
}

/*
========================================
Main Header
========================================
*/
.main-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 151px;
  background-color: rgb(0, 51, 153);
  color: #ffffff;
}

.main-header ul {
  list-style-type: none;
}

.main-header a:link {
  color: #ffffff;
  font-weight: normal;
}

.main-header a:visited {
  color: #ffffff;
}

/*
========================================
Search Bar
========================================
*/
.search-bar {
  display: grid;
  grid-template-columns: 0.25fr 1fr 0.25fr;
}

.search-bar form {
  justify-self: center;
  margin: 0;
}

.search-bar form [type="text"] {
  width: 160px;
}

.search-bar form [type="radio"] {
  margin-right: 0.33rem;
}

.search-bar ul {
  display: flex;
}

.submit-btn {
  margin: 8px;
  border: 1px solid black;
  padding: 2px 7px;
  background-color: #e0ffff;
  font-size: 10px;
  border-color: rgb(186, 186, 186);
}

.topnav {
  padding: 0;
  margin: 8px;
}

.topnav li:first-child::after {
  content: "|";
  margin: 0.33rem;
}

.home {
  justify-self: start;
}

.signup {
  justify-self: end;
}

/*
========================================
Navigation Bar
========================================
*/
.navbar {
  display: flex;
  align-items: center;
  margin-top: auto;
  background-color: rgb(102, 153, 204);
  height: 26px;
}

.navbar ul {
  display: flex;
  margin: 0 auto;
  padding: 0;
}

.navbar li::after {
  content: "|";
  margin: 0.33rem;
  color: black;
}

.navbar li:last-child::after {
  content: "";
}

.navbar a:hover {
  color: #003399;
}

/*
========================================
Page Content Container
========================================
*/
.page-content-container {
  display: grid;
  grid-template-columns: 325px 453px;
  grid-column-gap: 20px;
  grid-template-areas: "sidebar main";
  min-height: 500px;
}

/*
========================================
Profile Sidebar Column
========================================
*/
.profile-sidebar {
  grid-area: sidebar;
  margin-left: 27px;
  width: 300px;
}

h1 {
  font-size: 12.25pt;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 0px;
}

.profile-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/*
========================================
Profile Picture Container
========================================
*/

#name {
  min-height: 1.2rem;
}

.profile-picture-container {
  display: grid;
  grid-template-columns: 170px 115px;
  grid-template-rows: 176px;
  grid-column-gap: 15px;
  margin-bottom: 12px;
}

.profile-picture-container p {
  margin: 0;
}

.profile-pic {
  grid-area: 1/1;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 170px;
  height: 138px;
}

.personal-msg {
  grid-area: 1/2;
  margin: 0;
}

.gender-age {
  grid-area: 1/2;
  margin-top: 1rem;
}

#location {
  margin-top: 35px;
}

.last-login {
  grid-area: 1/2;
  align-self: end;
}

.last-login li:last-child {
  margin-bottom: 15px;
}

.pics-videos {
  grid-area: 1/1;
  justify-self: center;
  align-self: end;
}

/*
========================================
Contact Box
========================================
*/
.contact-box {
  border: 2px solid rgb(102, 153, 204);
  height: 149px;
  width: 300px;
  margin-bottom: 24px;
}

.contact-box h2 {
  color: white;
  background-color: rgb(102, 153, 204);
  height: 17px;
  padding-left: 10px;
}

.contact-images {
  display: grid;
  grid-template-columns: 150px 150px;
  margin: 0;
  margin-top: 5px;
  margin-left: 10px;
}

/*
========================================
MeSucSpace URL
========================================
*/
.MeSucSpace-url-box {
  border: 1px solid rgb(102, 153, 204);
  height: 26px;
  margin-bottom: 24px;
}

.MeSucSpace-url-box h3,
.MeSucSpace-url-box p {
  margin: 0;
  padding: 0;
}

.MeSucSpace-url-box h3 {
  font-size: 8pt;
  padding-left: 2px;
}

.MeSucSpace-url-box p {
  padding-left: 6px;
}

/*
========================================
Sidebar Table Class
========================================
*/
.sidebar-table {
  border: 2px solid rgb(102, 153, 204);
  display: table;
  border-collapse: separate;
  border-spacing: 3px;
}

.sidebar-table-h2 {
  background-color: rgb(102, 153, 204);
  color: white;
  text-align: left;
  height: 17px;
  padding-left: 15px;
  padding-top: 2px;
}

.sidebar-table-header {
  vertical-align: top;
  text-align: left;
  width: 107px;
  background-color: rgb(177, 208, 240);
  color: #336699;
  padding: 3px;
  font-weight: bold;
  font-size: 8pt;
}

.sidebar-table-data {
  width: 184px;
  background-color: rgb(213, 232, 251);
  padding: 3px;
}

/*
========================================
Interests
========================================
*/
.interests p {
  margin-top: 0;
}

.interests p:only-child,
.interests p:last-child {
  margin-bottom: 0;
}

.interests table {
  margin-bottom: 13px;
}

/*
========================================
Details
========================================
*/
.details table {
  margin-bottom: 22px;
}

/*
========================================
Schools
========================================
*/
.schools .sidebar-table-header {
  width: 228px;
  color: black;
  font-weight: normal;
}

.schools .sidebar-table-data {
  width: 63px;
  text-align: center;
  vertical-align: top;
}

.schools ul {
  margin-bottom: 2em;
}

/*
========================================
Main Column
========================================
*/
main {
  grid-area: main;
}

/*
========================================
User Network
========================================
*/
.user-network {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 433px;
  margin: auto;
  height: 75px;
  border: 2px solid black;
  margin-top: 13px;
  text-align: center;
}

.user-network h2 {
  font-size: 12pt;
}

/*
========================================
Blog
========================================
*/
.blog {
  margin-top: 13px;
  margin-left: 12px;
  margin-bottom: 16px;
  min-height: 340px;
}

.blog p {
  margin-top: 3px;
}

/*
========================================
Blurbs
========================================
*/
.main-section-header {
  background-color: #ffcc99;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 8px;
}

.main-section-h2 {
  color: #ff6600;
  margin-left: 20px;
}

.blurbs h3 {
  margin: 0;
  color: #ff6600;
  font-size: 9pt;
  margin-left: 15px;
}

.blurbs p:nth-of-type(5) {
  margin-bottom: 2em;
}

.blurbs p {
  margin: 0 10px 15px 15px;
}

.info {
  color: green;
  font-weight: bold;
}

/*
========================================
Friend Space
========================================
*/
.friends header {
  margin-bottom: 6px;
}

.friends p:nth-of-type(1) {
  margin-top: 0;
  margin-left: 15px;
}

.focus-highlight {
  color: #cc0000;
  font-weight: normal;
  font-size: 10pt;
}

.friend-pic-container.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 20px;
}

.requires-grid.friend-pic-container {
  display: grid;
  grid-template-columns: repeat(4, 109px);
}

.friend-pic-container figure {
  margin: 0;
}

.friend-pic-container figcaption {
  text-align: center;
}

.friend-pic-container img {
  display: block;
  margin: auto;
}

.friends-list-link {
  display: flex;
  justify-content: flex-end;
}

.friends-list-link {
  margin-top: 7px;
  margin-right: 8px;
  margin-bottom: 30px;
}

.friends-list-link a {
  color: #cc0000;
}

/*
========================================
Comment Wall
========================================
*/

.answer-content {
  margin-bottom: 1rem;
  white-space: pre-line;
}

.comment-wall .main-section-header {
  margin-bottom: 3px;
}

.comment-wall .main-section-h2 {
  margin-bottom: 0;
}

#comment-counter {
  margin-top: 0;
  margin-left: 15px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.comment-wall table {
  margin: auto;
  margin-bottom: 5px;
}

.comment-wall th {
  background-color: rgb(255, 153, 51);
  width: 158px;
  padding: 3px;
  vertical-align: top;
}

.comment-wall td {
  vertical-align: top;
  background-color: rgb(249, 214, 180);
  width: 269px;
  padding: 3px;
  position: relative;
}

.comment-wall figcaption,
.comment-wall figure {
  margin: 0;
}

.comment-wall figcaption {
  margin-bottom: 1em;
}

.comment-wall figure {
  margin-bottom: 49.33px;
}

.comment-wall h3 {
  font-size: 10pt;
  margin: 0;
  margin-bottom: 1em;
}

.comment-wall p {
  font-weight: normal;
  text-align: left;
  margin: 0;
}

p.add-comment {
  margin: 10px 5px 10px 0;
  text-align: right;
}

.comment-response {
  border-radius: 7px;
  padding: 5px;
  background: rgb(255 164 0);
  margin-bottom: 10px;
  position: relative;
}

.answer-response  .actions, 
.comment-response .actions {
  visibility: hidden;
  background-color: rgb(255 164 0);
  color: white;
  padding: 2px 6px 2px 4px;
  cursor: pointer;
  border-radius: 2px;
  position: absolute;
  right: -10px;
  top: 0;
}

.answer-response:hover  .actions, 
.comment-response:hover .actions {
  visibility: visible;
}

.answer-response .actions:hover,
.comment-response .actions:hover {
  background-color: rgb(255, 140, 0);
}

.comment-img {
  max-width: 150px;
}

.friend-pic-container img {
  width: 100px;
}

/*
========================================
Footer
========================================
*/
footer {
  display: grid;
  grid-template-rows: 16px 39px;
  background-color: #e5e5e5;
  padding-top: 10px;
  height: 65px;
}

footer a {
  text-decoration: underline;
  font-weight: normal;
  font-size: 8pt;
}

footer ul {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

footer li:after {
  content: " | ";
  font-weight: normal;
}

#last:after {
  content: none;
}

footer small {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  font-size: 8pt;
  font-weight: normal;
}

.fake-comment {
  color: rgb(0, 51, 153);
  font-weight: bold;
  text-decoration: none;
  cursor: default;
  width: fit-content;
}

.fake-comment:hover {
  text-decoration: underline;
  color: #cc0000;
}

.comment-box {
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  padding: 5px;
  min-height: 150px;
  min-width: 220px;
}

.q-a.comment-box { 
  width: 100%;
  min-height: 50px;
}

a.button:visited,
.button {
  border: 1px solid;
  width: fit-content;
  padding: 5px 15px;
  background-color: #039;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  text-decoration: none;
}

a.button:visited:hover,
.button:hover {
  background-color: #39c9fe;
  text-decoration: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

#parent .hidden-child {
  visibility: hidden;
}


@font-face {
  font-family: neon;
  src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf);
}

.neon {
  font-family: neon;
  color: #FB4264;
  font-size: 40px;
  text-shadow: 0 0 3vw #F40A35;
  /* color: #426DFB; */
  /* text-shadow: 0 0 3vw #2356FF; */
  top: 80px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.neon {
  animation: neon 1s ease infinite;
  -moz-animation: neon 1s ease infinite;
  -webkit-animation: neon 1s ease infinite;
}

@keyframes neon {
  0%,
  100% {
    text-shadow: 0 0 1vw #FA1C16, 0 0 3vw #FA1C16, 0 0 10vw #FA1C16, 0 0 10vw #FA1C16, 0 0 .4vw #FED128, .5vw .5vw .1vw #806914;
    color: #FED128;
  }
  50% {
    text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, .5vw .5vw .1vw #40340A;
    color: #806914;
  }
}

.flux {
  animation: flux 2s linear infinite;
  -moz-animation: flux 2s linear infinite;
  -webkit-animation: flux 2s linear infinite;
  -o-animation: flux 2s linear infinite;
}

@keyframes flux {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041FF, 0 0 3vw #1041FF, 0 0 10vw #1041FF, 0 0 10vw #1041FF, 0 0 .4vw #8BFDFE, .5vw .5vw .1vw #147280;
    color: #28D7FE;
  }
  50% {
    text-shadow: 0 0 .5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180, 0 0 5vw #082180, 0 0 .2vw #082180, .5vw .5vw .1vw #0A3940;
    color: #146C80;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: 45px;
}
.lds-ellipsis div {
  position: absolute;
  top: 70px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: salmon;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.your-profile .editable {
  cursor: context-menu;
}

.editable {
  white-space: pre-line;
}

.editor-container {
  background-color: white;
  padding: 15px 20px 10px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}

.background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: gray;
  opacity: 0.4;
  z-index: 1;
}

.editor-actions {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editor-actions > *:hover {
  cursor: pointer;
  opacity: 0.5;
}

.questions {
  max-width: 400px;
}

#drag {
  display: inline-block;
  width: 16px;
  height: 10px;
  cursor: grab;
  position: absolute;
  left: 2px;
  top: 6px;
}

#drag,
#drag::before {
  background-image: radial-gradient(black 40%, transparent 40%);
  background-size: 4px 4px;
  background-position: 0 100%;
  background-repeat: repeat-x;
}

#drag::before {
  content: '';
  display: block;
  width: 100%;
  height: 33%;
}

.q-box {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/mesuc-space.appspot.com/o/background.png?alt=media&token=573491e5-0443-4d70-b556-af01fd5a34b1);
  width: 100%;
  position: absolute;
  min-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset;
  transition-property: background-image;
  transition-duration: 180ms;
  transition-timing-function: ease-in-out;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.home-friends-container {
  background-color: white;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  margin: auto;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.logo {
  width: inherit;
  margin: auto;
}

.home-actions-container {
  margin: 10px auto;
  display: flex;
  gap: 10px 20px;
}

.home-header-container {
  min-height: 200px;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
}
